import * as React from 'react';
import { navigation } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';

export const BlogPostIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
	fill = navigation,
	width = 16,
	height = 16,
	...props
}) => {
	return (
		<SvgIcon height={height} width={width} {...props}>
			<g fill={fill} fillRule='evenodd'>
				<path d='M11.3846154,1.7776 L11.3846154,4.0576 L10.0338462,2.652 C9.76538462,2.8592 9.51153846,3.0752 9.28923077,3.3064 C7.17076923,5.5096 7.45,7.2688 8.23,8.0816 L6,10.4 L6,12 L9.23846154,8.6328 C10.1323077,8.8232 11.3769231,8.4816 12.8215385,6.9792 C14.9407692,4.7752 16,0 16,0 C16,0 13.5361538,0.5944 11.3846154,1.7776' />
				<path d='M10.4,16 L1.6,16 C0.7176,16 0,15.2824 0,14.4 L0,5.6 C0,4.7184 0.7176,4 1.6,4 L6.4,4 L6.4,5.6 L1.6,5.6 L1.6,14.4 L10.4,14.4 L10.4,9.6 L12,9.6 L12,14.4 C12,15.2824 11.2824,16 10.4,16' />
			</g>
		</SvgIcon>
	);
};
