import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { getSocialMediaPlatformLogoUrl } from '../../../../models/UiUtils';
import { baseStyleSheet } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { PopoverType, TinyPopover } from '../../TinyPopover';
import { BlogPostIcon } from '../../svgs/icons/BlogPostIcon';
import { EmailIcon } from '../../svgs/icons/EmailIcon';
import { InfoIcon } from '../../svgs/icons/InfoIcon';
import { StarIcon } from '../../svgs/icons/StarIcon';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	featured?: boolean;
	onClick?(e: React.MouseEvent<HTMLElement>): Promise<any>;
	styles?: StyleDeclarationValue[];
	suggestion: Api.IContentCalendarSuggestion;
	isMonthly?: boolean;
}

const getSubverticalText = (subverticals: string[]) => {
	if (subverticals?.length === 1) {
		return subverticals[0] === Api.ContentCalendarSuggestionAll ? 'All' : subverticals[0];
	}
	const substring = subverticals?.join(', ');
	return substring;
};

export const SuggestedCalendarEvent: React.FC<IProps> = props => {
	const { className, featured = false, styles = [], suggestion, onClick, isMonthly = false } = props;
	const [loading, setLoading] = React.useState<boolean>(false);

	const info = 'This campaign is recommended for ' + getSubverticalText(suggestion?.subverticals);
	const dateRangeText = suggestion.schedule
		? `${moment(suggestion.schedule.startDate).format('MMM Do')}${
				suggestion.schedule.expirationDate && suggestion.templateReference?.templateType === Api.TemplateType.Email
					? `\u2014 ${moment(suggestion.schedule.expirationDate).format('MMM Do')}`
					: ''
			}`
		: null;

	const clicked = async (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		setLoading(true);
		await onClick?.(e);
		setLoading(false);
	};

	const renderEventIcons = () => {
		return suggestion?.targets?.length > 0 ? (
			<div className={css(styleSheet.platformSection)}>
				{suggestion.targets
					?.sort((a, b) => (a > b ? 1 : -1))
					.map((x: Api.SocialMediaType, i: number) => (
						<figure
							className={css(styleSheet.platformImages, isMonthly && styleSheet.platformImagesDay)}
							key={`${x}-${i}`}
						>
							<img src={getSocialMediaPlatformLogoUrl(x)} />
						</figure>
					))}
			</div>
		) : suggestion.templateReference.templateType === Api.TemplateType.Blog ? (
			<div className={css(styleSheet.emailIcon, isMonthly && styleSheet.emailIconDay)}>
				{loading ? <LoadingSpinner type='tiny' /> : <BlogPostIcon fill='#fff' />}
			</div>
		) : (
			<div className={css(styleSheet.emailIcon, isMonthly && styleSheet.emailIconDay)}>
				{loading ? (
					<LoadingSpinner type='tiny' />
				) : (
					<EmailIcon fillColor='#fff' height={isMonthly ? 12 : 14} width={isMonthly ? 14 : 16} />
				)}
			</div>
		);
	};

	return (
		<div
			className={`${css(
				styleSheet.container,
				isMonthly && styleSheet.containerDay,
				...styles
			)} suggested-calendar-event ${className || ''}`}
			onClick={clicked}
		>
			<div
				className={css(
					styleSheet.title,
					isMonthly && styleSheet.titleDay,
					isMonthly && !!suggestion.targets?.length && styleSheet.titleDaySocial
				)}
			>
				{renderEventIcons()}
				<div className={css(styleSheet.titleIconRow)}>
					<div className={css(styleSheet.titleText, isMonthly ? styleSheet.titleTextDay : undefined)}>
						{!isMonthly ? <div>{dateRangeText} </div> : null}
						&nbsp;
						<div className={css(styleSheet.titleTextInfo)}>
							<span>Suggested</span>
						</div>
					</div>
					{featured ? (
						<StarIcon className={css(styleSheet.featuredIcon)} gradient={true} filled={true} height={13} width={13} />
					) : null}
					<TinyPopover
						align='end'
						anchor={<InfoIcon />}
						autoCloseOtherPopoversOnHover={true}
						anchorStyles={[styleSheet.iconContainer]}
						dismissOnOutsideAction={true}
						placement={['top', 'right']}
						toggleOnHover={true}
						type={PopoverType.lightBlue}
					>
						<div className={css(styleSheet.popover)}>{info}</div>
					</TinyPopover>
				</div>
			</div>
			<div className={css(styleSheet.name, isMonthly && styleSheet.nameDay, baseStyleSheet.fontBold)}>
				{suggestion.templateReference?.name}
			</div>
		</div>
	);
};
