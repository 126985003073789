import { StyleSheet } from 'aphrodite';
import { brandPrimary, titles, warningDark, warningLight } from '../../styles/colors';
import { CssSelectors, Layouts, cardStyle, ctaButtonProps } from '../../styles/styles';

const suggestionsToggleContainerHeight = 52;
const suggestionsToggleContainerBottomPadding = 10;
const pendingApprovalAlertHeight = 50;
const pendingApprovalAlertBottomMargin = 20;
const pendingApprovalAlertBorderWidth = 1;

export const styleSheet = StyleSheet.create({
	calendar: {
		height: '100%',
	},
	calendarContainer: {
		width: `calc(100% - 22rem)`,
	},
	calendarContainerCard: {
		...cardStyle,
		background: '#fff',
		boxSizing: 'border-box',
		overflow: 'auto',
		padding: '20px 20px',
	},
	calendarContainerCardWithAlert: {
		height: `calc(100vh - ${200 + pendingApprovalAlertHeight + 2 * pendingApprovalAlertBorderWidth + pendingApprovalAlertBottomMargin}px)`,
	},
	calendarContainerCardWithAlertAndSuggestions: {
		height: `calc(100vh - ${140 + pendingApprovalAlertHeight + 2 * pendingApprovalAlertBorderWidth + pendingApprovalAlertBottomMargin + suggestionsToggleContainerHeight + suggestionsToggleContainerBottomPadding}px)`,
	},
	calendarContainerCardWithSuggestions: {
		height: `calc(100vh - ${140 + suggestionsToggleContainerHeight + suggestionsToggleContainerBottomPadding}px)`,
	},
	campaignSearch: {
		flexGrow: 1,
	},
	campaignSelector: {
		height: `calc(100vh - 250px)`,
	},
	cardContainer: {
		height: 'calc(100% - 75px)',
	},
	campaignTemplateCardDragging: {
		opacity: 0.95,
	},
	campaignTemplateCardDraggingOverDay: {
		opacity: 0.9,
	},
	container: {
		boxSizing: 'border-box',
		height: '100%',
		width: '100%',
	},
	filterDropdownContainer: {
		display: 'flex',
		flex: 4,
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
		...CssSelectors.allChildren({
			':last-child': {
				marginLeft: 10,
			},
		}),
	},
	lastSuggestionRadio: {
		marginLeft: 10,
	},
	newCampaignCta: {
		marginLeft: 24,
		maxHeight: 40,
		minHeight: 40,
	},
	newContentModal: {
		color: titles,
		fontSize: 20,
		width: 420,
	},
	newContentModalBody: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		...Layouts.verticalStack(12),
	},
	newContentModalFooter: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		marginTop: 25,
	},
	newContentModalSubtitle: {
		color: '#4A4A4A',
		fontSize: 14,
	},
	pendingApprovalAlert: {
		alignItems: 'center',
		backgroundColor: warningLight,
		borderColor: warningDark,
		borderRadius: 3,
		borderStyle: 'solid',
		borderWidth: pendingApprovalAlertBorderWidth,
		display: 'flex',
		marginBottom: pendingApprovalAlertBottomMargin,
		height: pendingApprovalAlertHeight,
		width: '100%',
	},
	pendingApprovalAlertButton: {
		...ctaButtonProps('#EB8638'),
		fontSize: 14,
		padding: '4px 14px',
	},
	pendingApprovalAlertButtonContainer: {
		marginRight: 16,
	},
	pendingApprovalAlertText: {
		color: titles,
		flex: 1,
		fontSize: 14,
		marginLeft: 16,
	},
	searchContainer: {
		display: 'flex',
		marginBottom: 20,
	},
	suggestionsToggleContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		paddingBottom: suggestionsToggleContainerBottomPadding,
		height: suggestionsToggleContainerHeight,
		...CssSelectors.allChildren(
			{
				minHeight: suggestionsToggleContainerHeight / 2,
			},
			'label'
		),
	},
	suggestionsToggleText: {
		color: brandPrimary,
		fontSize: 14,
		marginLeft: 6,
	},
	suggestionTypeToggle: {
		display: 'flex',
		fontSize: 12,
		height: suggestionsToggleContainerHeight,
		justifyContent: 'space-between',
		...CssSelectors.allDescendants(
			{
				fontSize: 12,
			},
			'div'
		),
	},
	triggerContainer: {
		alignItems: 'center',
		display: 'flex',
		height: '100%',
		width: 250,
	},
	triggerContainerShowing: {
		maxWidth: 350,
		width: 'auto',
	},
	triggerLabel: {
		borderRight: '1px solid rgb(216,216,216)',
		color: '#D1d1d1',
		fontSize: 12,
		letterSpacing: 1.1,
		textTransform: 'uppercase',
		width: 120,
	},
	triggerValue: {
		paddingLeft: 10,
		paddingRight: 5,
	},
});
