import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	templateCard: {
		color: '#3F3F3F',
		paddingBottom: 20,
		width: '100%',
		...CssSelectors.allChildren(
			{
				color: brandPrimaryText,
			},
			'.campaign-template-card-name'
		),
	},
	templateCardContainer: {
		width: '100%',
	},
	templateCardDragging: {
		boxShadow: '0 0 10px 0 rgba(139,139,139,0.20)',
	},
	templateCardsWithIndustrySelector: {
		boxSizing: 'border-box',
		height: `calc(100% - ${84}px)`,
	},
	trigger: {
		height: '40px',
		padding: 0,
		paddingRight: 15,
	},
	triggerLabel: {
		borderRight: '1px solid rgb(216,216,216)',
		color: '#D1d1d1',
		fontSize: 12,
		letterSpacing: 1.1,
		paddingLeft: 10,
		paddingRight: 10,
		textTransform: 'uppercase',
		width: 160,
	},
	triggerText: {
		color: titles,
		paddingLeft: 10,
		width: '80%',
	},
});
