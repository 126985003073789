import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { DraggableProvided, DraggableStateSnapshot, DroppableProvided } from 'react-beautiful-dnd';
import { v4 as uuidgen } from 'uuid';
import { CopyTemplateLinkModal } from '../../../../admin/containers/templates/CopyTemplateLinkModal';
import { TemplateActions } from '../../../../admin/containers/templates/TemplateCategory';
import {
	CardSize,
	IImpersonationContextComponentProps,
	ImpersonationContextKey,
	InternalLevitateAccountIds,
} from '../../../../models';
import { CampaignType } from '../../../../models/AdminModels';
import { useEventLogging } from '../../../../models/Logging';
import { HiddenDefaultMessageTemplateKey } from '../../../../models/MessageTemplates';
import { storagePrefixExcludedFromClear } from '../../../../models/Storage';
import { canViewContentCalendarAndCampaigns, getIndustryName } from '../../../../models/UiUtils';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import { usePersistChangeInBrowserStorage } from '../../../../models/hooks/usePersistChangeInBrowserStorage';
import { ITemplateCard, KnownCategories } from '../../../../viewmodels/AppViewModels';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { IMoreMenuItem } from '../../../components/MoreMenu';
import { NewCampaignButtonsDropDown } from '../../../components/NewCampaignButtons';
import { ISelectOption, Select } from '../../../components/Select';
import { CampaignSearchInputField } from '../../../components/campaigns/CampaignSearchInputField';
import {
	BlogCampaignCalendarTemplateCard,
	SocialCampaignCalendarTemplateCard,
} from '../../../components/campaigns/CampaignsByCategorySelector/presentation';
import { DraggableObserver, DroppableObserver } from '../../../components/helpers/dnd';
import { CampaignTemplateCard } from '../../../components/templateCards/CampaignTemplateCard';
import { baseStyleSheet, bs } from '../../../styles/styles';
import { useContentCalendarTemplateBrowserQueries } from './hooks';
import { styleSheet } from './styles';

export interface IContentCalendarTemplateBrowserComponent {
	getTemplateCards: () => ITemplateCard[];
}

interface IProps extends IImpersonationContextComponentProps {
	cardCtaText?: string;
	dragDropProps: {
		droppableId: string;
		type: string;
		isDragDisabled?: boolean;
		/**
		 * Styles to apply to the template card while dragging
		 */
		draggingStyles?: StyleDeclarationValue[];
	};
	selectStyles?: StyleDeclarationValue[];
	selectedTemplateType?: CampaignType;
	hideCreateCampaignsButton?: boolean;
	onTemplateTypeSelected?(templateType: CampaignType): void;
	onFilterCategories?(categories: string[]): string[];
	onCampaignTemplateClicked?(template: Api.ITemplate, schedule?: Api.IScheduledSend, referrer?: string): void;
}

const defaultCopyMenu = [
	{
		name: 'Copy Direct Link',
		representedObject: 'copy',
	},
];

const ContentCalendarTemplateBrowserBase = React.forwardRef<IContentCalendarTemplateBrowserComponent, IProps>(
	function ContentCalendarTemplateBrowserBase(
		{
			impersonationContext,
			cardCtaText,
			selectStyles,
			onFilterCategories,
			onCampaignTemplateClicked,
			onTemplateTypeSelected,
			dragDropProps,
			hideCreateCampaignsButton,
		},
		ref
	) {
		const { draggingStyles, ...restDragDropProps } = dragDropProps || {};
		const userSession = useUserSession();
		const errorMessages = useErrorMessages();
		const logger = useEventLogging('ContentCalendarTemplateBrowser');

		const account = impersonationContext?.isValid ? impersonationContext.account : userSession?.account;
		const isPersonalAccount = !canViewContentCalendarAndCampaigns(userSession, impersonationContext);
		const blogsEnabled = account?.features?.blogFeature.enabled;
		const isSocialEnabled = account?.features?.socialMedia.enabled;
		const [industry, setIndustry] = usePersistChangeInBrowserStorage<Api.Industry>({
			storageKey: `${storagePrefixExcludedFromClear}-content-calendar-template-browser-industry`,
			storageType: 'localStorage',
			defaultIfEmpty: account.additionalInfo?.industry ?? Api.Industry.Insurance,
			skipPersistFn: () => impersonationContext?.isValid,
		});
		const [category, setCategory] = React.useState<string>(
			isPersonalAccount ? KnownCategories.MyTemplates : KnownCategories.Featured
		);
		const [searchQuery, setSearchQuery] = React.useState('');
		const [templateType, setTemplateType] = usePersistChangeInBrowserStorage<CampaignType>({
			storageKey: `${storagePrefixExcludedFromClear}-content-calendar-template-template-type`,
			storageType: 'localStorage',
			defaultIfEmpty: CampaignType.Email,
			skipPersistFn: () => impersonationContext?.isValid,
		});
		const [copyTemplate, setCopyTemplate] = React.useState<Api.ITemplate>(null);
		const [isCopyLinkModalOpen, setIsCopyLinkModalOpen] = React.useState<boolean>(false);

		const uuidRef = React.useRef<string>(uuidgen());

		const {
			isLoading,
			templatesByCategoryQuery,
			myTemplatesQuery,
			templatesQuery,
			templateSearchQuery,
			templateCategoriesQuery,
		} = useContentCalendarTemplateBrowserQueries({
			templateType,
			searchQuery,
			category,
			industry,
			impersonationContext,
			onCategoriesSuccess: categories => {
				setCategory(
					categories.find(x => x === KnownCategories.Featured) ?? categories.find(x => x === KnownCategories.All)
				);
			},
		});

		const onCampaignSearch = (query: string, newIndustry: Api.Industry) => {
			setIndustry(newIndustry);
			setSearchQuery(query);
		};

		const onCampaignTemplateCardClicked = (card: ITemplateCard, socialIndicator?: boolean) => async () => {
			const templateId = socialIndicator
				? card.associatedTemplates?.find(x => x.templateType === Api.TemplateType.SocialMediaPost)?.id
				: card.id;
			try {
				const template = await userSession.webServiceHelper.callAsync(
					Api.ImpersonationBroker.composeApiUrl({
						impersonationContext,
						urlPath: `template/${templateId}`,
						queryParams: { expand: 'LastUsedByDate' },
					}),
					'GET'
				);
				onCampaignTemplateClicked?.(template);
			} catch (error) {
				logger.logApiError('LoadTemplate-Error', error);
				errorMessages.pushApiError(error);
			}
		};

		const onMenuItemClicked = (template: Api.ITemplate) => async (menuItem: IMoreMenuItem<TemplateActions>) => {
			switch (menuItem.representedObject) {
				case 'copy':
					setCopyTemplate(template);
					setIsCopyLinkModalOpen(true);
					break;
				default:
					break;
			}
		};

		const templateTypeOptions = React.useMemo<ISelectOption<CampaignType>[]>(() => {
			{
				const types = [CampaignType.Email];
				if (isSocialEnabled) {
					types.push(CampaignType.Social);
				}
				if (blogsEnabled) {
					types.push(CampaignType.Blog);
				}
				return types.map(x => {
					return {
						dataContext: x,
						id: `template-type-option-${x}`,
						text: x.valueOf(),
					};
				});
			}
		}, [blogsEnabled, isSocialEnabled]);

		const templateCards = React.useMemo(() => {
			const templateIdsToRemove = localStorage.getItem(HiddenDefaultMessageTemplateKey);
			if (!searchQuery) {
				if (category === KnownCategories.All && templatesByCategoryQuery) {
					const templatesByCategory = templatesByCategoryQuery.data || {};
					return Object.keys(templatesByCategory).reduce<ITemplateCard[]>((result, curr) => {
						const categoryTemplates = templatesByCategory[curr];
						categoryTemplates.forEach(x => {
							if (!result.find(y => y.id === x.id)) {
								result.push(x);
							}
						});
						return result;
					}, []);
				} else if (category === KnownCategories.MyTemplates && myTemplatesQuery) {
					return myTemplatesQuery.data?.filter(x => !templateIdsToRemove?.includes(x.id));
				} else {
					return templatesQuery.data?.filter(x => !templateIdsToRemove?.includes(x.id));
				}
			} else {
				return templateSearchQuery.data?.filter(x => !templateIdsToRemove?.includes(x.id));
			}
		}, [
			category,
			myTemplatesQuery,
			searchQuery,
			templateSearchQuery.data,
			templatesByCategoryQuery,
			templatesQuery.data,
		]);

		const templateCardsRef = React.useRef<ITemplateCard[]>(templateCards);
		templateCardsRef.current = templateCards;

		const categoryOptions = React.useMemo<ISelectOption<string>[]>(() => {
			let categories = templateCategoriesQuery.data || [];
			if (onFilterCategories) {
				categories = onFilterCategories(categories);
			} else if (
				impersonationContext?.isValid &&
				!impersonationContext?.account?.preferences?.csmViewMyCampaignsEnabled
			) {
				categories = categories?.filter(x => x !== KnownCategories.MyTemplates);
			}

			return (
				categories?.map((x, i) => ({
					dataContext: x,
					id: `category-option-${uuidRef.current}-${i}`,
					text: x,
				})) || []
			);
		}, [
			impersonationContext?.account?.preferences?.csmViewMyCampaignsEnabled,
			impersonationContext?.isValid,
			onFilterCategories,
			templateCategoriesQuery.data,
		]);

		const industryOptions = React.useMemo(() => {
			const industriesBase = [
				Api.Industry.Insurance,
				Api.Industry.RealEstate,
				Api.Industry.Financial,
				Api.Industry.Mortgage,
				Api.Industry.Legal,
				Api.Industry.Accounting,
				Api.Industry.NonProfit,
				Api.Industry.HomeMaintenance,
				Api.Industry.Others,
			];

			const industries = InternalLevitateAccountIds.has(account?.id)
				? [...industriesBase, Api.Industry.Levitate]
				: industriesBase;
			return industries.map(x => {
				const name = getIndustryName(x);
				return {
					dataContext: x,
					id: `industry-option-${name}`,
					text: name,
				};
			});
		}, [account?.id]);

		React.useImperativeHandle(
			ref,
			() => {
				return {
					getTemplateCards: () => templateCardsRef.current || [],
				};
			},
			[]
		);

		React.useEffect(() => {
			if (onTemplateTypeSelected) {
				onTemplateTypeSelected(templateType);
			}
		}, [onTemplateTypeSelected, templateType]);

		const renderLabelTrigger = ({ label, text }: { label: string; text: string }) => {
			return (
				<>
					<span className={css(styleSheet.triggerLabel)}>{label}</span>
					<span className={css(styleSheet.triggerText)}>{text}</span>
				</>
			);
		};

		const renderTemplateTypeTrigger = () =>
			renderLabelTrigger({
				label: 'Type:',
				text: templateTypeOptions?.find(x => x.dataContext === templateType)?.text,
			});
		const renderIndustryTrigger = () =>
			renderLabelTrigger({ label: 'Industry:', text: industryOptions.find(x => x.dataContext === industry)?.text });
		const renderCategoryTrigger = () =>
			renderLabelTrigger({ label: 'Category:', text: categoryOptions?.find(x => x.dataContext === category)?.text });
		return (
			<div className={css(bs.flex, bs.flexCol, bs.gap4, bs.maxW80, bs.pb2)}>
				<div className={css(bs.flex, bs.gap2)}>
					<CampaignSearchInputField onChange={onCampaignSearch} industry={industry} styles={[bs.flex1]} />
					{hideCreateCampaignsButton ? null : <NewCampaignButtonsDropDown showingSocialType={isSocialEnabled} />}
				</div>
				<div className={css(bs.flex, bs.flexCol, bs.flex1, bs.relative, bs.overflowAuto, bs.gap3)}>
					{!isPersonalAccount ? (
						<>
							{templateTypeOptions?.length > 1 ? (
								<Select
									onOptionClick={option => setTemplateType(option.dataContext)}
									options={templateTypeOptions}
									selectedOptionTitle={renderTemplateTypeTrigger()}
									styles={[bs.wAuto, bs.maxWFull, ...(selectStyles || [])]}
									triggerStyles={[styleSheet.trigger]}
								/>
							) : null}
							<Select
								onOptionClick={option => setIndustry(option.dataContext)}
								options={industryOptions}
								selectedOptionTitle={renderIndustryTrigger()}
								styles={[bs.wAuto, bs.maxWFull, ...(selectStyles || [])]}
								triggerStyles={[styleSheet.trigger]}
							/>
							<Select
								onOptionClick={option => setCategory(option.dataContext)}
								options={categoryOptions}
								selectedOptionTitle={renderCategoryTrigger()}
								styles={[bs.wAuto, bs.maxWFull, ...(selectStyles || [])]}
								triggerStyles={[styleSheet.trigger]}
							/>
						</>
					) : null}
					{/* @ts-ignore */}
					<DroppableObserver {...restDragDropProps}>
						{(droppableProvided: DroppableProvided) => {
							return (
								<div
									className={css(bs.boxBorder, bs.flex, bs.flexCol, bs.overflowAuto, bs.flex1, bs.gap2)}
									ref={droppableProvided.innerRef}
									{...droppableProvided.droppableProps}
								>
									{templateCards?.map((card, i) => {
										return (
											<DraggableObserver
												disableInteractiveElementBlocking={true}
												draggableId={card.id}
												index={i}
												isDragDisabled={dragDropProps.isDragDisabled}
												key={card.id}
												type={dragDropProps.type}
											>
												{(draggableProvided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) => {
													return (
														<>
															<div
																ref={draggableProvided.innerRef}
																{...draggableProvided.draggableProps}
																{...draggableProvided.dragHandleProps}
															>
																{templateType === CampaignType.Email ? (
																	<CampaignTemplateCard
																		card={card}
																		className={css(styleSheet.templateCardContainer)}
																		copyMenu={defaultCopyMenu}
																		ctaText={cardCtaText}
																		key={card.id}
																		isAccountTemplate={category === KnownCategories.MyTemplates}
																		onCtaClicked={onCampaignTemplateCardClicked(card)}
																		onSocialClicked={onCampaignTemplateCardClicked(card, true)}
																		onMenuItemClicked={onMenuItemClicked(card)}
																		cardSize={CardSize.Small}
																		styles={[
																			styleSheet.templateCard,
																			draggableSnapshot.isDragging ? styleSheet.templateCardDragging : null,
																			draggableSnapshot.isDragging && draggingStyles ? draggingStyles : null,
																		]}
																	/>
																) : templateType === CampaignType.Blog ? (
																	<BlogCampaignCalendarTemplateCard
																		card={card}
																		key={card.id}
																		isFeatured={
																			categoryOptions.find(x => x.dataContext === category)?.text === 'Featured'
																		}
																		onCtaClicked={onCampaignTemplateCardClicked(card)}
																	/>
																) : (
																	<SocialCampaignCalendarTemplateCard
																		card={card}
																		key={card.id}
																		isFeatured={
																			categoryOptions.find(x => x.dataContext === category)?.text === 'Featured'
																		}
																		onCtaClicked={onCampaignTemplateCardClicked(card)}
																	/>
																)}
															</div>
															{draggableSnapshot.isDragging && draggableProvided?.placeholder}
														</>
													);
												}}
											</DraggableObserver>
										);
									})}
									{isLoading ? <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' /> : null}
									<CopyTemplateLinkModal
										isOpen={isCopyLinkModalOpen}
										onRequestClose={() => {
											setIsCopyLinkModalOpen(false);
											setCopyTemplate(null);
										}}
										industry={industry}
										template={copyTemplate}
									/>
								</div>
							);
						}}
					</DroppableObserver>
				</div>
			</div>
		);
	}
);

const ContentCalendarTemplateBrowserAsObserver = observer(ContentCalendarTemplateBrowserBase);
export const ContentCalendarTemplateBrowser = inject(ImpersonationContextKey)(ContentCalendarTemplateBrowserAsObserver);
