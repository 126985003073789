import { StyleSheet } from 'aphrodite';
import { brandPrimary, brandPrimaryText, brandSecondary } from '../../../styles/colors';
import { CssSelectors, Layouts } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	container: {
		backgroundColor: '#fff',
		borderColor: brandSecondary,
		borderStyle: 'dashed',
		borderWidth: 1,
		boxSizing: 'border-box',
		cursor: 'pointer',
		margin: 1,
		overflow: 'hidden',
		padding: 8,
		position: 'relative',
	},
	containerDay: {
		padding: 6,
	},
	emailIcon: {
		alignItems: 'center',
		backgroundColor: brandPrimary,
		borderRadius: 15,
		display: 'flex',
		height: 30,
		justifyContent: 'center',
		minWidth: 30,
		width: 30,
	},
	emailIconDay: {
		height: 18,
		minWidth: 18,
		width: 18,
	},
	featuredIcon: {
		display: 'flex',
		minWidth: 13,
	},
	iconContainer: {
		display: 'flex',
		marginLeft: 4,
	},
	name: {
		color: brandPrimaryText,
		fontSize: 12,
		marginLeft: 6,
		marginTop: 8,
	},
	nameDay: {
		fontSize: 10,
		marginLeft: 0,
		marginTop: 2,
	},
	platformImages: {
		display: 'flex',
		...CssSelectors.allChildren(
			{
				height: 20,
				marginRight: 5,
			},
			'img'
		),
	},
	platformImagesDay: {
		...CssSelectors.allChildren(
			{
				height: 14,
				marginRight: 4,
			},
			'img'
		),
	},
	platformSection: {
		display: 'flex',
		paddingRight: 2,
	},
	popover: {
		borderBottomRightRadius: 0,
		boxSizing: 'border-box',
		color: '#4A4A4A',
		fontSize: 12,
		lineHeight: '18px',
		marginLeft: 14,
		maxWidth: 300,
		padding: '6px 6px 6px 6px',
	},
	socialMediaIndicatorIcon: {
		position: 'absolute',
		right: 0,
		top: 0,
	},
	title: {
		alignItems: 'center',
		display: 'flex',
		height: 38,
	},
	titleDay: {
		height: 26,
	},
	titleDaySocial: {
		...CssSelectors.allChildren(
			{
				marginLeft: '0px !important',
			},
			'div'
		),
		alignItems: 'flex-start',
		flexDirection: 'column',
		height: 38,
	},
	titleIconRow: {
		...Layouts.horizontalStack(2),
		flex: 1,
		marginLeft: 4,
		marginTop: 2,
		width: '100%',
	},
	titleIconRow2: {
		flexDirection: 'column',
		marginTop: 0,
	},
	titleText: {
		alignItems: 'center',
		color: brandPrimaryText,
		display: 'flex',
		flexWrap: 'wrap',
		fontSize: 12,
		marginLeft: 4,
		width: 'calc(100% - 30px)',
	},
	titleTextDay: {
		fontSize: 10,
		marginRight: 4,
		marginLeft: 0,
		width: 'calc(100% - 22px)',
	},
	titleTextInfo: {
		display: 'flex',
		fontStyle: 'italic',
		justifyContent: 'space-between',
	},
});
