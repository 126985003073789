import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { DroppableProvidedProps } from 'react-beautiful-dnd';
import { Redirect } from 'react-router-dom';
import { dedupeByProvider } from '../../../../extViewmodels/Utils';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { CampaignType } from '../../../../models/AdminModels';
import { IEventLoggingComponentProps, useEventLogging, withEventLogging } from '../../../../models/Logging';
import {
	getCampaignCalendarDateRangeStringValue,
	getDisplayName,
	getSocialMediaPlatformLogoUrl,
	sortPlatformTargets,
} from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useTemplateCategorySocialQuery } from '../../../../queries';
import { ITemplateCard, KnownCategories } from '../../../../viewmodels/AppViewModels';
import { useEmailCampaignBrowserData } from '../../../containers/Campaigns/hooks';
import { CalendarType, CalendarViewGroupOptions } from '../../../containers/ContentCalendar/models';
import { brandPrimary, white } from '../../../styles/colors';
import { baseStyleSheet, bs } from '../../../styles/styles';
import { GhostCalendarLogoGraphic } from '../../svgs/graphics/GhostCalendarLogoGraphic';
import { BlogPostIcon } from '../../svgs/icons/BlogPostIcon';
import { CheckmarkIcon } from '../../svgs/icons/CheckmarkIcon';
import { DisclosureIcon } from '../../svgs/icons/DisclosureIcon';
import { EmailIcon } from '../../svgs/icons/EmailIcon';
import { StarIcon } from '../../svgs/icons/StarIcon';
import { SuggestedCalendarEvent } from '../SuggestedCalendarEvent';
import {
	EventDataType,
	ICalendarTimeSpan,
	ICampaignCalendarEventOptions,
	ICampaignCalendarEventPlaceholder,
	ICampaignCalendarTimeSpanOptions,
	SelectedPostStatuses,
} from './models';
import { styleSheet } from './styles';

interface ICampaignCalendarEventProps extends IImpersonationContextComponentProps, IEventLoggingComponentProps {
	campaignOrPlaceholder: Api.CampaignViewModel | ICampaignCalendarEventPlaceholder | Api.CampaignViewModel[];
	calendarTimeSpan?: ICalendarTimeSpan;
	onApproveCampaignClicked?(e: React.MouseEvent<HTMLElement>): void;
	onCampaignGroupClicked?(e: React.MouseEvent<HTMLElement>, campaigns: Api.CampaignViewModel[]): void;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	onRequestUser?(id: string): Api.UserViewModel | null;
	options?: ICampaignCalendarEventOptions;
	style?: React.CSSProperties;
	userSession?: Api.UserSessionContext;
	onNotifyClientClicked?(e: React.MouseEvent<HTMLElement>): void;
}

export const isWeekly = (type: CalendarType) => type === CalendarType.Weekly;

export const CampaignCalendarEvent = withEventLogging(
	inject(ImpersonationContextKey)(
		observer((props: ICampaignCalendarEventProps) => {
			const styles: StyleDeclarationValue[] = [];
			const account = props.impersonationContext?.account ?? props.userSession?.account;
			let status: React.ReactNode = null;
			const [redirection, setRedirection] = React.useState<string>(null);
			const [ownedByUser, setOwnedByUser] = React.useState<Api.UserViewModel>(null);
			const fetchUser = async (id: string) => {
				try {
					const user = props.onRequestUser?.(id);
					await user?.load();
					setOwnedByUser(user);
				} catch (error) {
					props.logApiError?.('UserLoad-Error', Api.asApiError(error));
				}
			};

			let sender: string = null;
			let sendStatus: Api.EmailSendStatus | Api.PostStatus | Api.BlogStatus = Api.EmailSendStatus.Unknown;
			let subject: string = null;
			let dateRangeNode: React.ReactNode = null;
			const renderDateRange = (schedule?: Api.IScheduledSend, completedDate?: string | Date) => {
				return (
					(schedule || completedDate) && (
						<div className={css(styleSheet.calendarEventDate)}>
							{getCampaignCalendarDateRangeStringValue(schedule?.startDate, completedDate)}
						</div>
					)
				);
			};

			const isCalendarTypeDay = props.calendarTimeSpan?.unitOfTime === 'day';

			if (Array.isArray(props.campaignOrPlaceholder)) {
				const campaigns = props.campaignOrPlaceholder;
				if (campaigns.length === 0) {
					return null;
				}
				sendStatus = campaigns.reduce((prev, campaign) => {
					if (
						prev === Api.EmailSendStatus.Queued ||
						prev === Api.EmailSendStatus.Ready ||
						prev === Api.EmailSendStatus.Sending ||
						prev === Api.EmailSendStatus.WaitingForApproval
					) {
						return prev;
					}

					return campaign.status;
				}, campaigns[0].status);
				sender = Api.VmUtils.getDisplayName(campaigns[0].creator);
				const firstCampaign = campaigns[0];
				subject = firstCampaign.templateReference?.name || firstCampaign.subject;
				dateRangeNode = renderDateRange(campaigns[0].schedule, null);
			} else {
				const campaign = props.campaignOrPlaceholder;
				sendStatus = campaign.status;
				// FOLLOWUP: Fix up the type with a templateReference
				// @ts-ignore
				subject = campaign.templateReference?.name || campaign.subject;
				dateRangeNode = renderDateRange(campaign.schedule, campaign.completedDate);
			}

			if (props.campaignOrPlaceholder instanceof Api.CampaignViewModel) {
				sender = Api.VmUtils.getDisplayName(props.campaignOrPlaceholder?.creator);
			}

			switch (sendStatus) {
				case Api.PostStatus.Scheduled: {
					styles.push(styleSheet.calendarEventSent);
					status = 'Scheduled';
					break;
				}
				case Api.PostStatus.Pending: {
					styles.push(styleSheet.calendarEventWaitingOnApproval);
					status = 'Pending';
					break;
				}
				case Api.EmailSendStatus.Complete: {
					styles.push(styleSheet.calendarEventSent);
					status = (
						<div className={css(baseStyleSheet.horizontalStack)}>
							<span>Sent</span>
							<CheckmarkIcon fillColor='#65AF1A' type='bold' />
						</div>
					);
					break;
				}
				case Api.EmailSendStatus.Queued:
				case Api.EmailSendStatus.Sending:
				case Api.EmailSendStatus.Ready: {
					styles.push(styleSheet.calendarEventScheduled);
					status = Array.isArray(props.campaignOrPlaceholder)
						? 'Scheduled'
						: props.campaignOrPlaceholder.status === Api.EmailSendStatus.Ready
							? 'Ready'
							: 'Scheduled';
					break;
				}
				case Api.EmailSendStatus.WaitingForApproval: {
					styles.push(styleSheet.calendarEventWaitingOnApproval);
					if (props.campaignOrPlaceholder instanceof Api.CampaignViewModel && props.options?.showScheduledFor) {
						const sorted = Api.VmUtils.sortContactFilterCriteria(props.campaignOrPlaceholder.filterRequest?.criteria);
						const ownedByCriteria = sorted.filters?.find(
							x =>
								x.property === Api.ContactFilterCriteriaProperty.OwnedBy && (!x.op || x.op === Api.FilterOperator.And)
						);
						if (ownedByCriteria?.value) {
							if (ownedByUser?.id !== ownedByCriteria.value) {
								fetchUser(ownedByCriteria.value);
							} else if (ownedByUser?.isLoaded) {
								status = `Newly scheduled for ${ownedByUser.firstName}`;
								break;
							}
						}
					}
					if (Array.isArray(props.campaignOrPlaceholder)) {
						status = 'Newly scheduled for contact owners';
						break;
					}
					status = 'Pending';
					break;
				}
				default: {
					break;
				}
			}
			const isPersonalAccount = account?.planDetails?.planId === 0;
			const campaignId =
				Object.prototype.hasOwnProperty.call(props.campaignOrPlaceholder, 'id') && !isPersonalAccount
					? (props.campaignOrPlaceholder as Api.CampaignViewModel).id
					: null;
			const onClick = Array.isArray(props.campaignOrPlaceholder)
				? (e: React.MouseEvent<HTMLElement>) => {
						props.onCampaignGroupClicked?.(e, props.campaignOrPlaceholder as Api.CampaignViewModel[]);
					}
				: campaignId
					? (e: React.MouseEvent<HTMLElement>) => {
							if (campaignId) {
								props.onClick?.(e);
								if (e.defaultPrevented) {
									return;
								}
								if (sendStatus === Api.EmailSendStatus.WaitingForApproval) {
									props.onApproveCampaignClicked(e);
								} else {
									setRedirection(`/reporting/group-email/${campaignId}`);
								}
							}
						}
					: undefined;

			const getElementDataProps = () => {
				return {};
			};
			return (
				<div
					className={css(
						styleSheet.calendarEvent,
						isCalendarTypeDay && styleSheet.calendarEventDay,
						onClick ? styleSheet.calendarEventClickable : null,
						...styles
					)}
					onClick={onClick}
					style={props?.style}
					{...getElementDataProps()}
				>
					<div className={css(baseStyleSheet.horizontalStack)}>
						<div
							className={`campaign-calendar-event-icon ${css(
								styleSheet.calendarEventIcon,
								isCalendarTypeDay && styleSheet.calendarEventIconDay
							)}`}
						>
							<EmailIcon fillColor='#fff' />
						</div>
						<div className={css(styleSheet.calendarEventHeader)}>
							{!isCalendarTypeDay ? dateRangeNode : null}
							<div className={`campaign-calendar-event-status ${css(styleSheet.calendarEventStatus)}`}>{status}</div>
						</div>
					</div>
					<div
						className={css(
							baseStyleSheet.fontBold,
							styleSheet.calendarEventSubject,
							isCalendarTypeDay && styleSheet.calendarEventSubjectDay
						)}
					>
						{subject}
					</div>
					<div className={css(styleSheet.calendarEventSender)}>
						<span className={css(baseStyleSheet.italicText)}>From: </span>
						<span className={css(baseStyleSheet.fontBold)}>{sender}</span>
					</div>
					{sendStatus === Api.EmailSendStatus.WaitingForApproval && (
						<div className={css(styleSheet.calendarEventFooter)}>
							{!props.options?.hideApprovalCtas ? (
								<button
									className={css(
										styleSheet.calendarEventAprroveButton,
										isCalendarTypeDay && styleSheet.calendarEventApproveButtonDay
									)}
									onClick={props.onApproveCampaignClicked}
								>
									<span>{account?.preferences?.complianceSettings?.enabled ? 'Action Needed' : 'Approve'}</span>
								</button>
							) : (
								<button
									className={css(
										styleSheet.calendarEventAprroveButton,
										isCalendarTypeDay && styleSheet.calendarEventApproveButtonDay
									)}
									onClick={props.onNotifyClientClicked}
								>
									<span>
										{`Notify Client${
											Array.isArray(props.campaignOrPlaceholder) && props.campaignOrPlaceholder?.length > 1 ? 's' : ''
										}`}
									</span>
								</button>
							)}
						</div>
					)}
					{redirection && <Redirect to={redirection} push={true} />}
				</div>
			);
		})
	),
	'CampaignCalendarEvent'
);

interface ISocialCalendarProps {
	onSocialClicked?(
		e: React.MouseEvent<HTMLElement>,
		socialMediaPost: Api.SocialMediaPostReportViewModel
	): Promise<any> | void;
	socialMediaPost: Api.SocialMediaPostReportViewModel;
}

interface ISocialCalendarEventProps
	extends ISocialCalendarProps,
		IImpersonationContextComponentProps,
		IEventLoggingComponentProps {
	calendarTimeSpan?: ICalendarTimeSpan;
	campaignOrPlaceholder:
		| Api.SocialMediaPostReportViewModel
		| ICampaignCalendarEventPlaceholder
		| Api.SocialMediaPostReportViewModel[];
	onApproveSocialCampaignClicked?(e: React.MouseEvent<HTMLElement>): void;
	onCampaignGroupClicked?(e: React.MouseEvent<HTMLElement>, campaigns: Api.CampaignViewModel[]): void;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	onRequestUser?(id: string): Api.UserViewModel | null;
	options?: ICampaignCalendarEventOptions;
	style?: React.CSSProperties;
	userSession?: Api.UserSessionContext;
	onNotifyClientClicked?(e: React.MouseEvent<HTMLElement>): void;
}
interface ISocialCalendarEventPlaceholderProps {
	campaignOrPlaceholder: ICampaignCalendarEventPlaceholder;
	isCalendarTypeDay?: boolean;
	subject?: string;
	date?: string;
}

export const SocialCalendarEventPlaceholder = observer((props: ISocialCalendarEventPlaceholderProps) => {
	return (
		<div className={css(styleSheet.calendarEventClickable)}>
			<div className={css(styleSheet.socialCard)}>
				<div
					className={css(
						styleSheet.suggestionCardContent,
						props.isCalendarTypeDay && styleSheet.suggestionCardContentDay
					)}
				>
					<div className={css(styleSheet.platformIconRow, props.isCalendarTypeDay && styleSheet.platformIconRowDay)}>
						<div className={css(styleSheet.platformSection)}>
							{Api.SupportedSocialMediaTypes?.sort((a, b) => (a > b ? 1 : -1)).map((x, i) => (
								<figure className={css(styleSheet.platformImages)} key={`${x}-${i}`}>
									<img src={getSocialMediaPlatformLogoUrl(x)} />
								</figure>
							))}
						</div>
						<div className={css(styleSheet.dateAndStatus, styleSheet.socialtextScheduled)}>
							<span>{props.date}</span>
							<span className={css(baseStyleSheet.italicText)}>Scheduled</span>
						</div>
					</div>
					<div
						className={css(
							baseStyleSheet.truncateText,
							styleSheet.socialtextScheduled,
							baseStyleSheet.fontBold,
							styleSheet.calendarEventSubject,
							props.isCalendarTypeDay && styleSheet.calendarEventSubjectDay
						)}
					>
						{props.subject}
					</div>
				</div>
			</div>
		</div>
	);
});

export const SocialCalendarEvent = withEventLogging(
	inject(ImpersonationContextKey)(
		observer((props: ISocialCalendarEventProps) => {
			const { socialMediaPost, onSocialClicked, calendarTimeSpan } = props;
			const onAddSuggestionToCalendarClicked = React.useCallback(
				async (e: React.MouseEvent<HTMLElement>) => {
					e.stopPropagation();
					await onSocialClicked(e, socialMediaPost);
				},
				[socialMediaPost, onSocialClicked]
			);

			const isCalendarTypeDay = calendarTimeSpan?.unitOfTime === 'day';
			const isSendOnBehalfFromFromAccountUser =
				socialMediaPost?.actor &&
				socialMediaPost.actor.id !== socialMediaPost.creator.id &&
				!(socialMediaPost.actor.isLevitateSupport && socialMediaPost.approvalRequests);

			const getElementDataProps = () => {
				return {};
			};

			const statusTextStyle =
				socialMediaPost.status === Api.PostStatus.Scheduled
					? styleSheet.socialtextScheduled
					: socialMediaPost.status === Api.PostStatus.Pending
						? styleSheet.socialTextPending
						: styleSheet.socialtextSent;

			const targetsToDisplay =
				socialMediaPost?.designatedTargets?.length > 0
					? dedupeByProvider(sortPlatformTargets(socialMediaPost.designatedTargets))
					: dedupeByProvider(sortPlatformTargets(socialMediaPost?.targets));
			return SelectedPostStatuses.includes(socialMediaPost.status) ? (
				<div
					className={css(styleSheet.calendarEventClickable)}
					onClick={onAddSuggestionToCalendarClicked}
					{...getElementDataProps}
				>
					<div
						className={css(
							socialMediaPost.status === Api.PostStatus.Scheduled
								? styleSheet.socialCard
								: socialMediaPost.status === Api.PostStatus.Pending
									? styleSheet.socialCardPending
									: styleSheet.socialCardSent
						)}
					>
						<div
							className={css(
								styleSheet.suggestionCardContent,
								isCalendarTypeDay && styleSheet.suggestionCardContentDay
							)}
						>
							<div className={css(styleSheet.platformIconRow, isCalendarTypeDay && styleSheet.platformIconRowDay)}>
								<div className={css(styleSheet.platformSection)}>
									{targetsToDisplay?.length > 0
										? targetsToDisplay.map((x: Api.IPostTarget, i: number) => (
												<figure
													className={css(styleSheet.platformImages, isCalendarTypeDay && styleSheet.platformImagesDay)}
													key={`${x}-${i}`}
												>
													<img
														className={
															(socialMediaPost.status !== Api.PostStatus.Scheduled &&
																x.state?.status !== Api.PostTargetStatus.Posted &&
																x.state?.status !== Api.PostTargetStatus.Unknown) ||
															(x.state?.lastConnectionState && x.state?.lastConnectionState?.state !== 'Connected') ||
															!x.state?.lastConnectionState?.postTargetId
																? css(styleSheet.imageOpacity)
																: undefined
														}
														src={getSocialMediaPlatformLogoUrl(x.provider)}
													/>
												</figure>
											))
										: null}
								</div>
								<div className={css(styleSheet.dateAndStatus, statusTextStyle)}>
									{isCalendarTypeDay ? null : (
										<span>{getCampaignCalendarDateRangeStringValue(socialMediaPost.dueDate)}</span>
									)}
									<span className={css(baseStyleSheet.italicText)}>
										{socialMediaPost.status === Api.PostStatus.Scheduled ? (
											'Scheduled'
										) : socialMediaPost.status === Api.PostStatus.Pending ? (
											'Pending'
										) : (
											<span className={css(baseStyleSheet.horizontalStack)}>
												<span>Posted</span>
												<CheckmarkIcon fillColor='#65AF1A' type='bold' />
											</span>
										)}
									</span>
								</div>
							</div>

							<div
								className={css(
									baseStyleSheet.truncateText,
									statusTextStyle,
									baseStyleSheet.fontBold,
									styleSheet.calendarEventSubject,
									isCalendarTypeDay && styleSheet.calendarEventSubjectDay
								)}
							>
								{socialMediaPost.name}
							</div>

							<div className={css(styleSheet.calendarEventSender)}>
								<div className={css(statusTextStyle, isCalendarTypeDay && baseStyleSheet.hidden)}>
									<span className={css(baseStyleSheet.italicText)}>
										{socialMediaPost.status === Api.PostStatus.Pending ||
										socialMediaPost.status === Api.PostStatus.Scheduled
											? 'Posting from: '
											: 'Posted From: '}
									</span>
									<span className={css(baseStyleSheet.fontBold)}>{getDisplayName(socialMediaPost.creator)}</span>
									{isSendOnBehalfFromFromAccountUser ? (
										<>
											<span className={css(baseStyleSheet.italicText)}>{' by '}</span>
											<span className={css(baseStyleSheet.fontBold)}>{socialMediaPost?.actor?.name}</span>
										</>
									) : null}
								</div>

								{socialMediaPost.status === Api.PostStatus.Pending ? (
									<div className={css(styleSheet.calendarEventFooter)}>
										{!props.options?.hideApprovalCtas ? (
											<button
												className={css(
													styleSheet.calendarEventAprroveButton,
													isCalendarTypeDay && styleSheet.calendarEventApproveButtonDay
												)}
												onClick={props.onApproveSocialCampaignClicked}
											>
												<span>
													{props.userSession?.account?.preferences?.complianceSettings?.enabled
														? 'Action Needed'
														: 'Approve'}
												</span>
											</button>
										) : (
											<button
												className={css(
													styleSheet.calendarEventAprroveButton,
													isCalendarTypeDay && styleSheet.calendarEventApproveButtonDay
												)}
												onClick={props.onNotifyClientClicked}
											>
												<span>Notify Client</span>
											</button>
										)}
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
			) : null;
		})
	),
	'SocialMediaCalendarEvent'
);

interface IBlogCalendarEventPlaceholderProps {
	campaignOrPlaceholder: ICampaignCalendarEventPlaceholder;
	isCalendarTypeDay?: boolean;
	subject?: string;
	date?: string;
}

export const BlogCalendarEventPlaceholder = observer((props: IBlogCalendarEventPlaceholderProps) => {
	return (
		<div className={css(styleSheet.calendarEventClickable, styleSheet.calendarEventScheduled)}>
			<div className={css(styleSheet.blogCard)}>
				<div
					className={css(
						styleSheet.suggestionCardContent,
						props.isCalendarTypeDay && styleSheet.suggestionCardContentDay
					)}
				>
					<div className={css(baseStyleSheet.horizontalStack)}>
						<div
							className={`campaign-calendar-event-icon ${css(
								styleSheet.calendarEventIcon,
								props.isCalendarTypeDay && styleSheet.calendarEventIconDay
							)}`}
						>
							<BlogPostIcon fill='#fff' />
						</div>
						<div className={css(styleSheet.calendarEventHeader)}>
							<div className={`campaign-calendar-event-status ${css(styleSheet.calendarEventStatus)}`}>Scheduled</div>
						</div>
					</div>
					<div
						className={css(
							baseStyleSheet.fontBold,
							styleSheet.calendarEventSubject,
							props.isCalendarTypeDay && styleSheet.calendarEventSubjectDay
						)}
					>
						{props.subject || (props.campaignOrPlaceholder?.dataContext as ITemplateCard)?.name}
					</div>
				</div>
			</div>
		</div>
	);
});

interface IBlogCalendarEventProps extends IImpersonationContextComponentProps, IEventLoggingComponentProps {
	blogReportView?: Api.IBlogReportView;
	placeholder?: ICampaignCalendarEventPlaceholder;
	calendarTimeSpan: ICalendarTimeSpan;
	onApproveClicked?(e: React.MouseEvent<HTMLElement>): void;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	onRequestUser?(id: string): Api.UserViewModel | null;
	options?: ICampaignCalendarEventOptions;
	style?: React.CSSProperties;
	onNotifyClientClicked?(e: React.MouseEvent<HTMLElement>): void;
}

export const BlogCalendarEvent = inject(ImpersonationContextKey)(
	({
		blogReportView,
		options,
		calendarTimeSpan,
		onRequestUser,
		style,
		onClick,
		onNotifyClientClicked,
		onApproveClicked,
		impersonationContext,
	}: IBlogCalendarEventProps) => {
		const styles: StyleDeclarationValue[] = [];

		const [ownedByUser, setOwnedByUser] = React.useState<Api.IUser>(null);
		const { logApiError } = useEventLogging('BlogCalendarEvent');
		const userSession = useUserSession();
		const isCalendarTypeDay = calendarTimeSpan.unitOfTime === 'day';
		const title = blogReportView?.title;
		const account = impersonationContext?.account ?? userSession?.account;

		const isSendOnBehalfFromFromAccountUser =
			blogReportView?.actor &&
			blogReportView.actor.id !== blogReportView.creator?.id &&
			!(blogReportView.actor.isLevitateSupport && blogReportView.approvalRequests);

		const fetchUser = async (id: string) => {
			try {
				const user = onRequestUser?.(id);
				await user?.load();
				setOwnedByUser(user.toJs());
			} catch (error) {
				logApiError?.('UserLoad-Error', Api.asApiError(error));
			}
		};

		let status: React.ReactNode = null;
		const sendStatus: Api.BlogStatus = blogReportView?.status;
		switch (sendStatus) {
			case Api.BlogStatus.Queued: {
				styles.push(styleSheet.calendarEventScheduled);
				status = 'Scheduled';
				break;
			}
			case Api.BlogStatus.Completed: {
				styles.push(styleSheet.calendarEventSent);
				status = (
					<div className={css(baseStyleSheet.horizontalStack)}>
						<span>Posted</span>
						<CheckmarkIcon fillColor='#65AF1A' type='bold' />
					</div>
				);
				break;
			}
			case Api.BlogStatus.Pending: {
				styles.push(styleSheet.calendarEventWaitingOnApproval);
				if (blogReportView && options?.showScheduledFor) {
					if (
						blogReportView.actor &&
						blogReportView?.creator &&
						blogReportView.creator.id !== blogReportView.actor.id
					) {
						fetchUser(blogReportView.creator.id);
					} else if (ownedByUser) {
						status = `Newly scheduled for ${ownedByUser.firstName}`;
						break;
					}
				}
				status = 'Pending';
				break;
			}
			default: {
				break;
			}
		}

		const getElementDataProps = () => {
			return {};
		};

		return (
			<div
				className={css(
					styleSheet.calendarEvent,
					isCalendarTypeDay && styleSheet.calendarEventDay,
					onClick ? styleSheet.calendarEventClickable : null,
					...styles
				)}
				onClick={onClick}
				style={style}
				{...getElementDataProps()}
			>
				<div className={css(baseStyleSheet.horizontalStack)}>
					<div
						className={`campaign-calendar-event-icon ${css(
							styleSheet.calendarEventIcon,
							isCalendarTypeDay && styleSheet.calendarEventIconDay
						)}`}
					>
						<BlogPostIcon fill='#fff' />
					</div>
					<div className={css(styleSheet.calendarEventHeader)}>
						<div className={`campaign-calendar-event-status ${css(styleSheet.calendarEventStatus)}`}>{status}</div>
					</div>
				</div>
				<div
					className={css(
						baseStyleSheet.fontBold,
						styleSheet.calendarEventSubject,
						isCalendarTypeDay && styleSheet.calendarEventSubjectDay
					)}
				>
					{title}
				</div>
				<div className={css(styleSheet.calendarEventSender)}>
					<span className={css(baseStyleSheet.italicText)}>
						{sendStatus === Api.BlogStatus.Pending || sendStatus === Api.BlogStatus.Queued
							? 'Posting from: '
							: 'Posted From: '}
					</span>
					<span className={css(baseStyleSheet.fontBold)}>{getDisplayName(blogReportView?.creator)}</span>
					{isSendOnBehalfFromFromAccountUser ? (
						<>
							<span className={css(baseStyleSheet.italicText)}>{' by '}</span>
							<span className={css(baseStyleSheet.fontBold)}>{blogReportView?.actor?.name}</span>
						</>
					) : null}
				</div>
				{sendStatus === Api.BlogStatus.Pending ? (
					<div className={css(styleSheet.calendarEventFooter)}>
						{!options?.hideApprovalCtas ? (
							<button
								className={css(
									styleSheet.calendarEventAprroveButton,
									isCalendarTypeDay && styleSheet.calendarEventApproveButtonDay
								)}
								onClick={onApproveClicked}
							>
								<span>{account?.preferences?.complianceSettings?.enabled ? 'Action Needed' : 'Approve'}</span>
							</button>
						) : (
							<button
								className={css(
									styleSheet.calendarEventAprroveButton,
									isCalendarTypeDay && styleSheet.calendarEventApproveButtonDay
								)}
								onClick={onNotifyClientClicked}
							>
								<span>Notify Client</span>
							</button>
						)}
					</div>
				) : null}
			</div>
		);
	}
);

const getStartDateFromEvent = (event: EventDataType) => {
	let result: moment.MomentInput = null;
	if (event instanceof Api.SocialMediaPostReportViewModel) {
		result = event.dueDate;
	} else if (event instanceof Api.CampaignViewModel) {
		result = event.schedule?.startDate;
	} else if (Array.isArray(event)) {
		result = event[0].schedule?.startDate;
	} else if (event._type === 'BlogReportView') {
		const reportView: Api.IBlogReportView = event as Api.IBlogReportView;
		result = reportView.scheduledSendDate;
	}
	return result;
};

export const CampaignCalendarTimeSpan: React.FunctionComponent<{
	calendarType: CalendarType;
	droppableProps: DroppableProvidedProps;
	hideCampaigns?: boolean;
	impersonationContext?: Api.IImpersonationContext;
	isDraggingOver?: boolean;
	calendarTimeSpan: ICalendarTimeSpan;
	onApproveCampaignClicked?(campaign: Api.CampaignViewModel): void;
	onApproveSocialCampaignClicked?: (campaign: Api.SocialMediaPostReportViewModel) => void;
	onCampaignClicked?(e: React.MouseEvent<HTMLElement>, campaign: Api.CampaignViewModel): void;
	onCampaignGroupClicked?(e: React.MouseEvent<HTMLElement>, campaigns: Api.CampaignViewModel[]): void;
	onBlogPostClicked?(e: React.MouseEvent<HTMLElement>, campaign: Api.IBlogReportView): void;
	onInnerRef?(element: HTMLElement | null): any;
	onNotifyClientClicked?(campaign: EventDataType): void;
	onRenderSuggestion?(suggestion: Api.IContentCalendarSuggestion): React.ReactNode;
	onRequestUser?(id: string): Api.UserViewModel | null;
	onSocialMediaEventClicked?(
		e: React.MouseEvent<HTMLElement>,
		social: Api.SocialMediaPostReportViewModel
	): Promise<any>;
	onSuggestedSocialMediaEventClicked?(
		e: React.MouseEvent<Element>,
		suggestion: Api.IContentCalendarSuggestion
	): Promise<any>;
	onSuggestionClicked?(e: React.MouseEvent<HTMLElement>, suggestion: Api.IContentCalendarSuggestion): Promise<any>;
	options?: ICampaignCalendarTimeSpanOptions;
	placeholders?: ICampaignCalendarEventPlaceholder[];
	socialMediaPosts?: Api.SocialMediaPostReportViewModel[];
	style?: React.CSSProperties;
	suggestions?: Api.IContentCalendarSuggestion[];
	templateType?: CampaignType;
	userSession?: Api.UserSessionContext;
	styleSheet?: any;
}> = inject(ImpersonationContextKey)(
	observer(props => {
		const { onSuggestionClicked, onSuggestedSocialMediaEventClicked } = props;
		const onApproveCampaignClicked = (campaign: Api.CampaignViewModel) => (e: React.MouseEvent<HTMLElement>) => {
			e.stopPropagation();
			props.onApproveCampaignClicked?.(campaign);
		};
		const onApproveSocialCampaignClicked =
			(campaign: Api.SocialMediaPostReportViewModel) => (e: React.MouseEvent<HTMLElement>) => {
				e.stopPropagation();
				props.onApproveSocialCampaignClicked?.(campaign);
			};

		const onCampaignClicked = (campaign: Api.CampaignViewModel) => (e: React.MouseEvent<HTMLElement>) => {
			props.onCampaignClicked?.(e, campaign);
		};
		const onBlogPostClicked = (campaign: Api.IBlogReportView) => (e: React.MouseEvent<HTMLElement>) => {
			props.onBlogPostClicked?.(e, campaign);
		};

		const onNotifyClientClicked = (campaign: EventDataType) => (e: React.MouseEvent<HTMLElement>) => {
			e.stopPropagation();
			props.onNotifyClientClicked?.(campaign);
		};

		const featuredQuerySocial = useTemplateCategorySocialQuery({
			categoryName: KnownCategories.Featured,
			enabled: true,
			industry:
				props.impersonationContext?.account?.additionalInfo.industry ||
				props.userSession?.account?.additionalInfo?.industry,
		});

		const { featuredQuery: featuredQueryEmail } = useEmailCampaignBrowserData(
			props.impersonationContext?.account?.additionalInfo.industry
		);

		const { featuredSuggestions, suggestions } = React.useMemo(() => {
			const featured: Api.IContentCalendarSuggestion[] = [];
			const regular: Api.IContentCalendarSuggestion[] = [];

			const allFeaturedIds = new Set<string>(
				(featuredQueryEmail?.data || []).concat(featuredQuerySocial?.data || []).map(x => x.id)
			);

			props.suggestions?.forEach(el => {
				(allFeaturedIds.has(el.templateReference?.templateId) ? featured : regular).push(el);
			});

			return { featuredSuggestions: featured, suggestions: regular } as const;
		}, [featuredQueryEmail?.data, featuredQuerySocial?.data, props.suggestions]);

		const calendarEventItems: EventDataType[] = React.useMemo(() => {
			let items: EventDataType[] = (props?.calendarTimeSpan.campaigns || []).filter(x => {
				if (x.status === Api.EmailSendStatus.Cancelled) {
					return false;
				}
				if (props.calendarTimeSpan?.moment && x.schedule?.startDate) {
					if (!props.calendarTimeSpan.moment.isSame(x.schedule.startDate, props.calendarTimeSpan.unitOfTime)) {
						return false;
					}
				}
				return true;
			});
			const socialItems: EventDataType[] = (props?.calendarTimeSpan?.socialCampaigns || []).filter(x => {
				if (props.calendarTimeSpan?.moment && x.dueDate) {
					if (!props.calendarTimeSpan.moment.isSame(x.dueDate, props.calendarTimeSpan.unitOfTime)) {
						return false;
					}
				}
				return true;
			});

			const blogItems: Api.IBlogReportView[] = (props?.calendarTimeSpan?.blogReports || []).filter(x => {
				if (props.calendarTimeSpan?.moment && x.scheduledSendDate) {
					if (!props.calendarTimeSpan.moment.isSame(x.scheduledSendDate, props.calendarTimeSpan.unitOfTime)) {
						return false;
					}
				}
				return true;
			});
			items = [...items, ...socialItems, ...blogItems];
			items.sort((a, b) => {
				const aTime = getStartDateFromEvent(a);
				const bTime = getStartDateFromEvent(b);
				return moment(aTime).isBefore(bTime) ? -1 : 1;
			});
			if (props.options?.groupEventsByGroupId) {
				items = items.reduce<EventDataType[]>((res, x) => {
					if (!Array.isArray(x) && x instanceof Api.CampaignViewModel) {
						const groupedCampaigns = props.calendarTimeSpan.campaignsByGroupId?.[x.groupId];
						if (groupedCampaigns?.length > 0) {
							if (groupedCampaigns?.length === 1) {
								res.push(groupedCampaigns[0]);
							} else {
								if (res.indexOf(groupedCampaigns) < 0) {
									res.push(groupedCampaigns);
								}
							}
						} else {
							res.push(x);
						}
						return res;
					}

					res.push(x);
					return res;
				}, []);
			}
			return items;
		}, [
			props.calendarTimeSpan?.blogReports,
			props.calendarTimeSpan?.campaigns,
			props.calendarTimeSpan?.campaignsByGroupId,
			props.calendarTimeSpan?.moment,
			props.calendarTimeSpan?.socialCampaigns,
			props.calendarTimeSpan?.unitOfTime,
			props.options?.groupEventsByGroupId,
		]);

		const isCalendarTypeDay = props.calendarTimeSpan?.unitOfTime === 'day';

		const suggestionClicked = React.useCallback(
			(suggestion: Api.IContentCalendarSuggestion) => (e: React.MouseEvent<HTMLElement>) => {
				return suggestion?.targets?.length > 0
					? onSuggestedSocialMediaEventClicked?.(e, suggestion)
					: onSuggestionClicked?.(e, suggestion);
			},
			[onSuggestedSocialMediaEventClicked, onSuggestionClicked]
		);

		const isTodayOrActiveMonth =
			(isWeekly(props.calendarType) && props.calendarTimeSpan.moment.isSame(moment(), 'day')) ||
			(props.calendarType === CalendarType.Quarterly && props.calendarTimeSpan.moment.isSame(moment(), 'month'));

		const getTitleStyles = () => {
			const styles = [styleSheet.calendarMonthTitleText];
			if (props.calendarType === CalendarType.Quarterly) {
				styles.push(styleSheet.quarterTitle);

				if (props.calendarTimeSpan.moment.isBefore(moment(), 'month')) {
					styles.push(styleSheet.pastQuarterTitle);
				}
			} else if (props.calendarType === CalendarType.Month) {
				styles.push(styleSheet.dayOfTheMonth);
				if (props.calendarTimeSpan.moment.isSame(moment(), 'day')) {
					styles.push(styleSheet.today);
				}
			}

			if (isWeekly(props.calendarType) && props.calendarTimeSpan.moment.isBefore(moment(), 'day')) {
				styles.push(styleSheet.pastWeekTitle);
			}
			if (isTodayOrActiveMonth) {
				styles.push(styleSheet.activeMonthOrDay);
			}
			return styles;
		};

		return (
			<div
				className={css(
					styleSheet.calendarMonth,
					isCalendarTypeDay && styleSheet.calendarMonthDay,
					isWeekly(props.calendarType) ? styleSheet.calendarMonthWeekDay : null
				)}
				ref={props.onInnerRef}
				style={props?.style}
				{...props.droppableProps}
			>
				<div
					className={css(
						styleSheet.calendarMonthTitle,
						isCalendarTypeDay && styleSheet.calendarMonthTitleDay,
						isWeekly(props.calendarType) ? styleSheet.calendarMonthWeekTitleDay : null
					)}
				>
					<time dateTime={`${props.calendarTimeSpan.title}`} className={css(getTitleStyles())}>
						{props.calendarTimeSpan.title || ''}
					</time>
				</div>
				<div
					className={`${css(
						styleSheet.calendarMonthEvents,
						props.calendarType === CalendarType.Quarterly ? styleSheet.calendarMonthQuarterlyEvents : undefined,
						isWeekly(props.calendarType) ? styleSheet.calendarMonthWeeklyEvents : undefined,
						isTodayOrActiveMonth ? styleSheet.calendarEventsInnerToday : undefined
					)}`}
				>
					{isWeekly(props.calendarType) ? (
						<div style={{ padding: '6px 10px 0' }}>
							<time
								dateTime={`${props.calendarTimeSpan.moment.format('dddd Do')}`}
								className={css(
									styleSheet.dayOfTheMonth,
									props.calendarTimeSpan.moment.isBefore(moment(), 'day') && styleSheet.pastWeekTitle,
									props.calendarTimeSpan.moment.isSame(moment(), 'day') && styleSheet.today
								)}
							>
								{`${props.calendarTimeSpan.moment.get('date')}`}
							</time>
						</div>
					) : null}
					<div className={`${css(styleSheet.calendarEventsInner)}`}>
						{props.onRenderSuggestion ? (
							props.suggestions.map(x => {
								return props.onRenderSuggestion(x);
							})
						) : (
							<>
								{featuredSuggestions?.length ? (
									<>
										<div className={css(styleSheet.featuredHeader)}>
											<StarIcon height={13} fillColor={white} filled={true} />
											Featured Content
										</div>
										{featuredSuggestions.map((x, i) => {
											return (
												<SuggestedCalendarEvent
													key={`suggested-${x.id}-${i}`}
													onClick={suggestionClicked(x)}
													suggestion={x}
													featured={true}
													isMonthly={isCalendarTypeDay}
												/>
											);
										})}
										<div className={css(styleSheet.notFeaturedSection, isCalendarTypeDay && baseStyleSheet.hidden)} />
									</>
								) : null}
								{suggestions?.map((x, i) => {
									return (
										<SuggestedCalendarEvent
											key={`suggested-${x.id}-${i}`}
											onClick={suggestionClicked(x)}
											suggestion={x}
											isMonthly={isCalendarTypeDay}
										/>
									);
								})}
							</>
						)}
					</div>
					{props.placeholders?.length > 0 &&
						props.placeholders.map((placeholder, i) => {
							const key = `${placeholder.moment?.toISOString() ?? ''}-${placeholder.status ?? ''}-${placeholder.subject || ''}-${i}`;
							return props.templateType === CampaignType.Email || props.templateType === undefined ? (
								<CampaignCalendarEvent
									campaignOrPlaceholder={placeholder}
									key={`email-${key}`}
									calendarTimeSpan={props.calendarTimeSpan}
									userSession={props.userSession}
								/>
							) : props.templateType === CampaignType.Blog ? (
								<BlogCalendarEventPlaceholder
									campaignOrPlaceholder={placeholder}
									isCalendarTypeDay={isCalendarTypeDay}
									key={`blog-${key}`}
								/>
							) : (
								<SocialCalendarEventPlaceholder
									campaignOrPlaceholder={placeholder}
									isCalendarTypeDay={isCalendarTypeDay}
									key={`social-media-${key}`}
								/>
							);
						})}
					{!props.hideCampaigns &&
						(calendarEventItems.length === 0 && props.placeholders?.length === 0 ? (
							!props.calendarTimeSpan.unitOfTime.toLocaleLowerCase().includes('day') &&
							moment(props.calendarTimeSpan.moment).endOf(props.calendarTimeSpan.unitOfTime).isAfter(moment()) ? (
								<div className={css(baseStyleSheet.verticalStack, styleSheet.calendarMonthEmptyPlaceholder)}>
									<div
										className={css(
											styleSheet.calendarEventIcon,
											isCalendarTypeDay && styleSheet.calendarEventIconDay,
											styleSheet.calendarMonthEmptyPlaceholderIcon
										)}
									>
										<EmailIcon fillColor='#fff' />
									</div>
									<ul>
										<li>No campaigns,</li>
										<li>drag one in!</li>
									</ul>
								</div>
							) : null
						) : (
							calendarEventItems.map((calendarEventItem, i) => {
								if (calendarEventItem instanceof Api.SocialMediaPostReportViewModel) {
									return calendarEventItem.status === Api.PostStatus.Succeeded ||
										calendarEventItem.status === Api.PostStatus.PartiallySucceeded ||
										calendarEventItem.status === Api.PostStatus.Started ||
										calendarEventItem.status === Api.PostStatus.Scheduled ||
										calendarEventItem.status === Api.PostStatus.Pending ? (
										<SocialCalendarEvent
											key={`social-media-post-report-${calendarEventItem.id}`}
											onSocialClicked={props.onSocialMediaEventClicked}
											socialMediaPost={calendarEventItem}
											onNotifyClientClicked={onNotifyClientClicked(calendarEventItem)}
											options={props.options?.eventOptions}
											onApproveSocialCampaignClicked={onApproveSocialCampaignClicked(calendarEventItem)}
											campaignOrPlaceholder={calendarEventItem}
											calendarTimeSpan={props.calendarTimeSpan}
										/>
									) : null;
								} else if (calendarEventItem instanceof Api.CampaignViewModel || Array.isArray(calendarEventItem)) {
									return (
										<CampaignCalendarEvent
											key={`campaign-${
												Array.isArray(calendarEventItem) ? calendarEventItem[0].groupId : calendarEventItem.id
											}-${i}`}
											campaignOrPlaceholder={calendarEventItem}
											calendarTimeSpan={props.calendarTimeSpan}
											onApproveCampaignClicked={
												Array.isArray(calendarEventItem) ? undefined : onApproveCampaignClicked(calendarEventItem)
											}
											onCampaignGroupClicked={props.onCampaignGroupClicked}
											onClick={Array.isArray(calendarEventItem) ? undefined : onCampaignClicked(calendarEventItem)}
											onNotifyClientClicked={onNotifyClientClicked(calendarEventItem)}
											onRequestUser={props.onRequestUser}
											options={props.options?.eventOptions}
											userSession={props.userSession}
										/>
									);
								} else if (calendarEventItem._type === 'BlogReportView') {
									return (
										<BlogCalendarEvent
											blogReportView={calendarEventItem}
											calendarTimeSpan={props.calendarTimeSpan}
											key={`blog-${calendarEventItem.id}-${i}`}
											onNotifyClientClicked={onNotifyClientClicked(calendarEventItem)}
											onRequestUser={props.onRequestUser}
											options={props.options?.eventOptions}
											onClick={onBlogPostClicked(calendarEventItem)}
											onApproveClicked={onBlogPostClicked(calendarEventItem)}
										/>
									);
								}

								return null;
							})
						))}
				</div>
				{props.isDraggingOver ? <div className={css(styleSheet.calendarMonthDropTarget)} /> : null}
			</div>
		);
	})
);

interface ICampaignCalendarTitleBarProps {
	calendarType: CalendarType;
	onCalendarTypeChanged(selectedCalendarType: CalendarType): void;
	canGoBack?: boolean;
	canGoForward?: boolean;
	end: ICalendarTimeSpan | null;
	goBack(): Promise<any>;
	goForward(): Promise<any>;
	onRenderHeaderAccessory?(): React.ReactNode;
	showGhost?: boolean;
	start: ICalendarTimeSpan;
}

export const CampaignCalendarTitleBar = ({
	goForward,
	goBack,
	onRenderHeaderAccessory,
	end,
	showGhost,
	start,
	calendarType,
	onCalendarTypeChanged,
}: ICampaignCalendarTitleBarProps) => {
	const [disabled, setDisabled] = React.useState(false);
	const enable = () => setDisabled(false);
	const go = (forward: boolean) => () => {
		(forward ? goForward : goBack)().then(enable)?.catch(enable);
	};
	const accessory = onRenderHeaderAccessory?.();
	return (
		<>
			<div className={css(styleSheet.calendarTitleBar, showGhost && styleSheet.calendarTitleBarGhost)}>
				<div
					className={css(
						styleSheet.calendarTitleBarContent,
						!accessory ? styleSheet.calendarTitleBarContentNoAccessory : null
					)}
				>
					<button
						disabled={disabled}
						onClick={go(false)}
						className={css(styleSheet.buttonChevron, styleSheet.buttonChevronLeft)}
					>
						<DisclosureIcon
							className={css(styleSheet.calendarTitleBarBackIcon)}
							fillColor={brandPrimary}
							type='chevron'
						/>
						Previous
					</button>
					<div className={css(bs.horizontalStack)}>
						{showGhost ? (
							<div>
								<GhostCalendarLogoGraphic className={css(styleSheet.calendarGhostIcon)} /> :
							</div>
						) : null}

						<div className={css(styleSheet.timeWrap)}>
							{!end ? (
								<time dateTime={`${start.title}, ${start.moment.format('YYYY')}`}>
									{`${start.title} ${start.moment.format('YYYY')}`}
								</time>
							) : (
								<time dateTime={`${start.title} \u2014 ${end?.title}, ${end?.moment.format('YYYY')}`}>
									{isWeekly(calendarType) ? (
										<>
											{`${start.moment.format('MMMM Do')} \u2014 ${end?.moment.format(moment(start.moment).isSame(end.moment, 'month') ? 'Do YYYY' : 'MMMM Do YYYY')}`}
										</>
									) : (
										<>{`${start.title} \u2014 ${end?.title} ${end?.moment.format('YYYY')}`}</>
									)}
								</time>
							)}
						</div>
					</div>
					<button
						disabled={disabled}
						onClick={go(true)}
						className={css(styleSheet.buttonChevron, styleSheet.buttonChevronRight)}
					>
						<DisclosureIcon
							className={css(styleSheet.calendarTitleBarForwardIcon)}
							fillColor={brandPrimary}
							type='chevron'
						/>
						Next
					</button>
				</div>
				{accessory}
			</div>

			<menu
				className={css(
					styleSheet.calendarMenu,
					calendarType === CalendarType.Month ? styleSheet.calendarMenuMonthSelected : undefined
				)}
			>
				{CalendarViewGroupOptions.map(selectOption => {
					return (
						<li key={selectOption.id}>
							<button
								className={css(
									styleSheet.calendarOption,
									calendarType === selectOption.dataContext ? styleSheet.activeCalendarOption : undefined
								)}
								onClick={() => onCalendarTypeChanged(selectOption.dataContext)}
							>
								{selectOption.text}
							</button>
						</li>
					);
				})}
			</menu>
		</>
	);
};
