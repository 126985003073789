import * as React from 'react';
import { usePixabayImageSearchQuery } from '../../../../queries';
import { useMediaChooserContext } from '../context';

export function useMediaChooserSearch() {
	const inputRef = React.useRef(null);
	const { setSelectedImage, setShowSuggestions } = useMediaChooserContext();

	// @ts-ignore
	const [pendingSearchTerm, setPendingSearchTerm] = React.useState<string>(null);
	// @ts-ignore
	const [searchTerm, setSearchTerm] = React.useState<string>(null);

	// Queries

	const {
		fetchNextPage: fetchNextImages,
		hasNextPage: hasNextImagePage,
		data: searchedImages,
		isLoading,
	} = usePixabayImageSearchQuery({ searchTerm });

	const onClearClicked = () => {
		// @ts-ignore
		setSearchTerm(null);
		// @ts-ignore
		setPendingSearchTerm(null);
		setShowSuggestions(true);
	};

	const onSearchTermInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		// @ts-ignore
		setSearchTerm(null);
		setPendingSearchTerm(e.target.value);
	};

	const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onCtaClicked();
		}
	};

	const getMoreImages = async () => {
		if (hasNextImagePage) {
			fetchNextImages();
		}
	};

	const onCtaClicked = () => {
		setSearchTerm(pendingSearchTerm);
		// @ts-ignore
		setSelectedImage(null);
		setShowSuggestions(false);
	};

	return {
		getMoreImages,
		inputRef,
		isLoading,
		onClearClicked,
		onCtaClicked,
		onInputKeyDown,
		onSearchTermInputChanged,
		pendingSearchTerm,
		searchedImages,
		searchTerm,
	} as const;
}
